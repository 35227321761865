<template>
  <div class="competitor-component" :class="classes">
    <!-- for organizer and duels add link -->
    <router-link
      v-if="isLink"
      class="avatar"
      :to="{ name: 'account', params: { userID: id } }"
    >

      <CompetitorAvatar :avatarSize="avatarSize" :src="avatar" :name="name" :is-agree="status === 1 && !organizer" />

      <span v-if="showOrganizer">Organizer</span>
    </router-link>

    <span v-else class="avatar">
      <CompetitorAvatar :avatarSize="avatarSize" :src="avatar" :name="name" :is-agree="status === 1" />

      <span v-if="showOrganizer">Organizer</span>
    </span>

    <div class="info" v-if="showInfo">
      <div class="name">
        <span> {{ name }} </span>
      </div>

      <div class="more">
        <div class="country" v-if="country">
          <img :src="country.icon" alt="Flag"/>

          <span> {{ country.code }} </span>
        </div>

        <div v-if="competition.entity === 'competition'" class="raiting">
          <Icon :size="16" type="star"/>

          <span> {{ raiting }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import competitor from '@/mixins/competitor'
import CompetitorAvatar from '@/components/Competitions/Competitor/CompetitorAvatar'

const modes = ['inline', 'avatar', 'vertical']

export default {
  name: 'Competitor',
  mixins: [
    competitor
  ],
  props: {
    competitor: {
      type: Object,
      required: true
    },
    competition: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: modes[0],
      validator (value) {
        return modes.includes(value)
      }
    },
    isLink: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CompetitorAvatar,
    Icon,
  },
  computed: {
    classes () {
      if(this.competition.entity === 'competition'){
        const classes = [this.mode]

        if (!this.completed) {
          classes.push(this.statusName)
        }

        return classes
      }else{
        return null
      }
    },
    showWinner () {
      return this.mode === modes[1] && this.winner
    },
    showInfo () {
      return this.mode !== modes[1]
    },
    showOrganizer () {
      return this.mode === modes[2] && this.organizer
    },
    avatarSize () {
      return this.mode === modes[1] && this.winner ? 44 : 36
    }
  },
  methods: {
    getInitials() {
      return this.competitor.name.split(' ').slice(0, 2).map(e => e[0].toUpperCase()).join('');
    }
  }
}

</script>

<style lang="stylus" scoped>
.ant-avatar-badge {
  position: absolute;
  top: -5px;
  right: -8px;
}
</style>
