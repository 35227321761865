<template>
  <div class="duel">
    <div class="duel__vs-image">
      <img src="@/assets/img/imgVs.png" alt="PvP" />
    </div>

    <div
      v-for="(competitor, index) in competitors"
      :key="competitor.id"
      class='duel__competitor'
    >
      <DuelCompetitor :competitor="competitor" :isOpposite="index === 1" />
    </div>
  </div>
</template>

<script>
import competitor from '@/mixins/competitor';
import DuelCompetitor from '@/components/Competitions/Competitor/DuelCompetitor'

export default {
  components: { DuelCompetitor },
  mixins: [ competitor ],
  props: {
    competitors: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      avatarSize: 40,
    }
  }
}
</script>

<style lang="stylus" scoped>
  .duel
    display: flex
    justify-content: space-between
    flex-grow: 1
    position: relative

    &__vs-image {
      position absolute
      overflow-x hidden
      width 56px
      height: 114px;
      display flex
      justify-content center
      left: calc(50% - 28px)
      top: calc(50% - 57px);
    }

    &__competitor {
      width: calc(50% - 35px);
    }
</style>
