<template>
  <router-link :is="id ? 'router-link' : 'span'" :to="{
    name: competition.entity === 'event' ? (isLive ? 'streamed-event' : 'details-event') : 'details',
    params: { id }
  }" class="competition-component" :class="classes">
    <!--    <pre>-->
    <!--      {{ competition.status }} &#45;&#45; {{ competition.id }}-->
    <!--    </pre>-->

    <!--      <pre>-->
    <!--        {{ competition.challenges }}-->
    <!--      </pre>-->
    <div class='competition-component__header'>
      <h2 class='competition-component__title'>Competition by</h2>
      <Icon v-if="competition.kind_sport && competition.kind_sport.name"
        :type="`sport-${competition.kind_sport.name.toLowerCase()}`" :size="24" />
    </div>

    <div v-if="competition.entity === 'competition' && competitors.length > 0" class="competitors">
      <div class="versus" v-if="showVersusCompetitors">
        <DuelBlock :competitors="competitorsFiltered" />
      </div>
      <Competitors v-if="showAllCompetitors && !showVersusCompetitors" :competitors="competitorsFiltered"
        :organizer="organizer" :competition="competition" />
    </div>
    <div v-if="competition.entity === 'event'" class="competitors">
      <Competitors :competitors="competition.competitors" :organizer="competition.organizer" :competition="competition" />
    </div>

    <div class="date">
      <div class="event-date">
        <span> {{ date }} </span>
      </div>

      <div class="timer" v-if="timer">
        <Icon :size="17" type="time" />

        <span> {{ timer }} </span>
      </div>
    </div>

    <div v-if="competition.entity === 'competition'" class="details-wrap top-separator">
      <div v-if="competition.kind_sport.name === 'Swimming'" class="details">
        <div class="detail">
          <span class="name"> {{ $t('details.gender') }} </span>
          <span class="value"> {{ this.gender_participants }} </span>
        </div>
        <div class="detail" v-for="(value, name) in details" :key="name">
          <span class="name"> {{ $t('details.' + name) }} </span>
          <span class="value"> {{ value }} </span>
        </div>
      </div>

      <div v-if="competition.kind_sport.name === 'Basketball'" class="details">
        <span class="name"> Discipline </span>
        <span class="value"> Around the world </span>
      </div>
    </div>

    <div v-if="competition.entity === 'event'" class="details-wrap top-separator">
      <div class="details">
        <div class="detail">
          <span class="name"> {{ $t('details.gender') }} </span>

          <span class="value" style="text-transform: capitalize;"> {{ this.competition.gender_participants ?
            this.competition.gender_participants
            : this.currentHeat.gender_participants ||
            this.gender_participants }} </span>
        </div>
        <div class="detail" v-for="(value, name) in competition.details" :key="name">
          <span class="name"> {{ value.name }} </span>
          <span class="value"> {{ value.value }} </span>
        </div>
        <!-- <div class="detail"> НЕ ВИДАЛЯТИ!!!
          <span class="name"> Viewers </span>
          <span class="value"> {{ competition.viewers }} </span>
        </div> -->
      </div>
    </div>
    <div v-if="showActions && competition.entity === 'competition'" class='competition-component__footer top-separator'>

      <!-- является участником соревнования -->
      <template v-if="isMemberOfCompetition">

        <!-- соревнование создано (еще никто не согласился учавствовать) -->
        <template v-if="competition.status === 'awaiting'">
          <CommonButton @clicked="share()" icon-before="share-1" icon-size="20" variant="inline" caption="share" />

          <!-- disabled "compete" button for organizer -->
          <CommonButton v-if='organized' :disabled="true" icon-size="20" icon-before="sword-solid" variant="inline"
            color="start" caption="compete" />
        </template>

        <!-- хотя бы 1 участник (кроме организатора) нажал "accept" -->
        <template v-if="competition && competition.status === 'created'">
          <CommonButton @clicked="share()" icon-before="share-1" icon-size="20" variant="inline" caption="share" />
          <CommonButton v-if="!isCurrentUserUploadedVideo && !competitionIsEnd" :disabled="currUserAwaiting"
            @clicked="competeAction" icon-size="20" icon-before="sword-solid" variant="inline" color="start"
            caption="compete" />

          <!-- disabled для карточки на странице фида, что бы юзер, который не нажал Accept на сорвеновании, не мог отправить видео -->
          <!-- :is-disabled='participation && !organized && isCurrentUserAcceptedInvite' -->

          <!-- кнопка "awaiting results" не нужна, т.к. происходит редирект и обновление данных с API-->
        </template>

        <!-- хотя бы 1 участник загрузил видео -->
        <template v-if="competition.status === 'performances-are-uploaded'">
          <CommonButton @clicked="share()" icon-before="share-1" icon-size="20" variant="inline" caption="share" />

          <CommonButton v-if="!isCurrentUserUploadedVideo && !currUserReject" @clicked="competeAction" icon-size="20"
            icon-before="sword-solid" variant="inline" color="start" caption="compete" :disabled="currUserAwaiting" />
          <CommonButton v-if="isCurrentUserUploadedVideo && !currUserReject && !currUserWaitingAfterMarks" is-disabled
            icon-size="20" icon-before="awaiting-clock" variant="inline" color="start" caption="awaiting results" />
        </template>

        <template v-if="competition.status === 'performances-are-marked-up' ||
          competition.status === 'judging-is-done' ||
          competition.status === 'video-post-prod-is-done' ||
          competition.status === 'video-is-scheduled'
          ">
          <CommonButton @clicked="share()" icon-before="share-1" icon-size="20" variant="inline" caption="share" />
          <!-- DEMO MODE BLOCK /////////////////////////////////////////////////////// -->
          <div v-if="!basketballStatus">
            <CommonButton v-if="!isCurrentUserUploadedVideo && !competitionIsEnd" :disabled="currUserAwaiting"
              @clicked="competeAction" icon-size="20" icon-before="sword-solid" variant="inline" color="start"
              caption="compete" />
          </div>
          <!-- DEMO MODE BLOCK ///////////////////////////////////////////////////// -->
          <div v-else>
            <CommonButton v-if="!currUserReject && !currUserWaitingAfterMarks" is-disabled icon-size="20"
              icon-before="awaiting-clock" variant="inline" color="start" caption="AwaItinG ResUlts" />
          </div>
        </template>

        <!-- Резуьлтаты отображаются в отдельном компоненте -->
        <!-- Видео опубликовано. Соревнование закончено -->
        <!--        <template-->
        <!--          v-if="competition.status === 'video-is-live '">-->
        <!--          <CommonButton @clicked="share()" icon-before="share-1" icon-size="20" variant="inline" caption="share" />-->
        <!--          <CommonButton-->
        <!--            is-disabled-->
        <!--            icon-size="20"-->
        <!--            icon-before="awaiting-clock"-->
        <!--            variant="inline"-->
        <!--            color="start"-->
        <!--            caption="awaiting results"-->
        <!--          />-->
        <!--        </template>-->

        <!-- никто из учасников не принял заявку (организатора не считаем) ИЛИ никто не загрузил ни одного видео-->
        <!-- <template v-if="competition.status === 'cancelled'">status: cancelled</template> -->
      </template>

      <!-- НЕ является участником соревнования -->
      <template v-if="!isMemberOfCompetition">
        <!-- всегда находится слева -->
        <CommonButton @clicked="share()" icon-before="share-1" icon-size="20" variant="inline" caption="share" />

        <!-- <template v-if="competition.status === 'video-is-live'">
          <CommonButton :isPrevent="false" icon-size="20" icon-before="play-circle" variant="inline" color="info" caption="View event" />
        </template> -->
      </template>
    </div>
    <div v-if="competition.entity === 'event'" class='competition-component__footer top-separator'>
      <CommonButton @clicked="share()" icon-before="share-1" icon-size="20" variant="inline" caption="share" />
      <!--closed-->
      <template v-if="isOpenEvent">
        <CommonButton v-if="(regOpen && !isParticipation) || currentHeat === 'new-single-event'" :isPrevent="false"
          icon-size="20" icon-before="plus" variant="inline" color="begin" caption="Join" />
        <CommonButton v-else-if="(regOpen || regClosed) && currUserAccepted" :disabled="true" icon-size="20"
          icon-before="sword-solid" variant="inline" color="start" caption="compete" />
      </template>
      <!--not closed-->
      <template v-else-if="!isOpenEvent && competition.me_has_challenges">
        <CommonButton v-if="(awaitingCreated || regClosed) && isParticipation && currUserAccepted" :disabled="true"
          icon-size="20" icon-before="sword-solid" variant="inline" color="start" caption="compete" />
        <CommonButton v-else-if="regOpen && !isParticipation && !currUserAccepted" :isPrevent="false" icon-size="20"
          icon-before="plus" variant="inline" color="begin" caption="Join" />
      </template>
      <CommonButton v-if="startListWithoutVideo" icon-size="20" icon-before="sword-solid" variant="inline" color="start"
        caption="compete" @clicked="eventCompeteNow()" />
      <CommonButton v-if="startListWithVideo" is-disabled icon-size="20" icon-before="awaiting-clock" variant="inline"
        color="start" :isPrevent="false" caption="awaiting results" />
      <CommonButton v-if="isParticAndAccept && afterStartList" is-disabled icon-size="20" icon-before="awaiting-clock"
        variant="inline" :isPrevent="false" color="start" caption="awaiting results" />
      <CommonButton v-if="isLive" :isPrevent="false" icon-size="20" icon-before="play-circle" variant="inline"
        color="info" caption="View event" />
    </div>

    <Popup :open="isShowPopupCompetNow" @close="isShowPopupCompetNow = false">
      <h4>{{ $t('competition.popup.waitingCompetNow.title') }}</h4>

      <template slot="actions">
        <a-button @click="isShowPopupCompetNow = false">
          <Icon :size="17" type="check" />
          {{ $t('competition.popup.waitingCompetNow.ok') }}
        </a-button>
      </template>
    </Popup>
    <Popup :open="isShowPopupShare" @close="isShowPopupShare = false">
      <div class="competition__share">
        <p v-if="linkWasCopied" class="competition__copied">
          {{ $t('competition.copyPopup.success') }}
        </p>
        <button @click="copySharedLink">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.8833 6.75845L12.0625 7.93761C12.6042 8.47929 13.0339 9.12238 13.3271 9.83015C13.6203 10.5379 13.7712 11.2965 13.7712 12.0626C13.7712 12.8287 13.6203 13.5873 13.3271 14.2951C13.0339 15.0028 12.6042 15.6459 12.0625 16.1876L11.7675 16.4818C10.6735 17.5758 9.18964 18.1904 7.64247 18.1904C6.09529 18.1904 4.61149 17.5758 3.51747 16.4818C2.42345 15.3878 1.80884 13.904 1.80884 12.3568C1.80884 10.8096 2.42345 9.3258 3.51747 8.23178L4.69664 9.41094C4.30694 9.79724 3.99739 10.2567 3.78574 10.7629C3.5741 11.2692 3.46453 11.8123 3.46333 12.361C3.46212 12.9097 3.56931 13.4532 3.77874 13.9604C3.98817 14.4676 4.29571 14.9284 4.6837 15.3164C5.0717 15.7044 5.53251 16.0119 6.03968 16.2213C6.54686 16.4308 7.0904 16.538 7.63911 16.5368C8.18781 16.5356 8.73088 16.426 9.23713 16.2143C9.74338 16.0027 10.2028 15.6931 10.5891 15.3034L10.8841 15.0084C11.6653 14.2271 12.1041 13.1675 12.1041 12.0626C12.1041 10.9578 11.6653 9.89814 10.8841 9.11678L9.70497 7.93761L10.8841 6.75928L10.8833 6.75845ZM16.4816 11.7676L15.3033 10.5893C15.693 10.203 16.0025 9.74352 16.2142 9.23727C16.4258 8.73103 16.5354 8.18796 16.5366 7.63925C16.5378 7.09054 16.4306 6.547 16.2212 6.03983C16.0118 5.53266 15.7042 5.07184 15.3162 4.68385C14.9282 4.29585 14.4674 3.98831 13.9603 3.77888C13.4531 3.56946 12.9095 3.46227 12.3608 3.46347C11.8121 3.46467 11.2691 3.57424 10.7628 3.78589C10.2566 3.99753 9.7971 4.30709 9.4108 4.69678L9.1158 4.99178C8.33467 5.77314 7.89586 6.83276 7.89586 7.93761C7.89586 9.04246 8.33467 10.1021 9.1158 10.8834L10.295 12.0626L9.1158 13.2409L7.93747 12.0626C7.39573 11.5209 6.966 10.8778 6.67281 10.1701C6.37962 9.4623 6.22871 8.70371 6.22871 7.93761C6.22871 7.17152 6.37962 6.41293 6.67281 5.70515C6.966 4.99738 7.39573 4.35429 7.93747 3.81261L8.23247 3.51845C9.32649 2.42443 10.8103 1.80981 12.3575 1.80981C13.9046 1.80981 15.3885 2.42443 16.4825 3.51845C17.5765 4.61246 18.1911 6.09627 18.1911 7.64345C18.1911 9.19062 17.5765 10.6744 16.4825 11.7684L16.4816 11.7676Z"
              fill="#7B8B97" />
          </svg>
          <span>Copy Link to this Event</span>
        </button>
      </div>
    </Popup>

    <Popup :open="isShowPopupWait" @close="isShowPopupWait = false">
      <h4>{{ $t('competition.popup.waiting.title') }}</h4>

      <p>{{ $t('competition.popup.waiting.description') }}</p>

      <template slot="actions">
        <a-button @click="isShowPopupWait = false">
          <Icon :size="17" type="check" />
          {{ $t('competition.popup.waiting.ok') }}
        </a-button>
      </template>
    </Popup>

    <Popup :height="popupHeight" :open="popup" @close="togglePopup">
      <template v-if="showWaitingPopup">
        <h4>{{ $t('competition.popup.waiting.title') }}</h4>

        <p>{{ $t('competition.popup.waiting.description') }}</p>

        <template slot="actions">
          <a-button @click="togglePopup">
            <Icon :size="17" type="check" />

            {{ $t('competition.popup.waiting.ok') }}
          </a-button>
        </template>
      </template>

      <template v-if="showCompetePopup">
        <h4>{{ $t('competition.popup.compete.title') }}</h4>

        <template slot="actions">
          <router-link :custom="true" v-slot="{ navigate }"
            :to="this.competition.entity === 'competition' ? { name: 'record', params: { id } } : { name: 'record', params: { 'id': getCurrId } }">
            <a-button @click="navigate">
              <Icon :size="20" type="check" />

              {{ $t('competition.popup.compete.ok') }}
            </a-button>
          </router-link>

          <a-button class="secondary" @click="togglePopup">
            {{ $t('competition.popup.compete.cancel') }}
          </a-button>
        </template>
      </template>
    </Popup>
  </router-link>
</template>

<script>
import Icon from '@/components/Icon'
import Popup from '@/components/Popup'
import CommonButton from '@/components/UI/CommonButton'
import Competitors from '@/components/Competitions/Competitors'
import DuelBlock from '@/components/Competitions/Competitor/DuelBlock'
import competition from '@/mixins/competition'
import { mapGetters } from 'vuex'
import { copyTextToClipboard, checkCanCompetitionShare } from '@/helpers'
import { getEvent } from '@/api/public/competition'

export default {
  name: 'Competition',
  mixins: [competition],
  props: {
    competition: {
      type: Object,
      required: true
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Icon,
    Popup,
    Competitors,
    CommonButton,
    DuelBlock
  },
  data() {
    return {
      popup: false,
      isShowPopupShare: false,
      isShowPopupWait: false,
      linkWasCopied: false,
      isShowPopupCompetNow: false,
      propCometition: this.competition
    }
  },
  computed: {
    ...mapGetters({
      participant: 'participant'
    }),
    //DEMO MODE BLOCK//////////////////////////////////////////////////////////////
    basketballStatus() {
      if (this.competition.kind_sport.name === "Basketball") {
        if (!this.isCurrentUserUploadedVideo) {
          return (competition.status === 'judging-is-done' ||
            competition.status === 'video-post-prod-is-done' ||
            competition.status === 'video-is-scheduled')
        }
      }
      return true
    },
    //DEMO MODE BLOCK//////////////////////////////////////////////////////////////
    classes() {
      return {
        preview: this.preview
      }
    },
    currentHeat() {
      if (this.competition.entity !== 'event') {
        return false
      }
      if (this.competition.competitions.length === 0 && this.competition.challenges.length === 0) {
        return 'new-single-event'
      }
      if (this.competition.type === 'open') {
        for (let heat of this.competition.competitions) {
          //           return heat
          if (heat.challenges.length === 0 && this.competition.competitions.length === 0) {
            return heat
          } else if (this.competition.competitions.length === 1) {
            return heat
          } else {
            let res = heat.challenges.find(el => el.opponent.id === this.$store?.state?.auth?.user?.id)
            if (res || heat.challenges.length < 8 && heat.status === 'registration-open') {
              return heat
            }
          }
        }
        return false
      } else {
        for (let heat of this.competition.competitions) {
          if (heat.challenges) {
            let res = heat && heat.challenges.find(el => el.opponent.id === this.$store?.state?.auth?.user?.id)
            if (res) {
              return heat
            }
          }
        }
        return false
      }
    },
    popupHeight() {
      return this.showWaitingPopup ? 300 : 220
    },
    showActions() {
      return !this.preview
    },
    isAwaiting() {
      return this.awaiting && !this.inprogress
    },
    showSaveAction() {
      return !this.participation
    },
    showInprogress() {
      return this.inprogress
    },
    showWaitingPopup() {
      return this.awaiting
    },
    showCompetePopup() {
      return !this.awaiting
    },
    showAllCompetitors() {
      return (
        this.competition.challenges.length > 1 ||
        this.isCompetitionHaveInvitedPersons
      )
    },
    showVersusCompetitors() {
      // duel if >>> 1 challenge + Have Invited + No Added Hash Invite
      return (
        (this.competition.challenges.length === 1 && this.isCompetitionHaveInvitedPersons && this.competition.invites_with_hashes.length === 0) ||
        (this.competition.challenges.length === 1 && this.preview) || this.competitorsFiltered.length === 2
      )
    },
    competitionIsEnd() {
      return new Date(this.competition.event_at) < Date.now();
    },
    iAmViewer() {
      const me = this.$store.state.auth.user.id;
      return !this.competitors.some(e => e.id === me) && !this.competitionIsEnd;
    },
    currUserReject() {
      const me = this.$store.state.auth.user.id;
      let el = this.competition.challenges.find((el) => el.opponent.id === me && el.status === "Rejected")
      if (el) {
        return true
      }
      return false
    },
    currUserAccepted() {
      const me = this.$store.state.auth.user.id;
      let el = this.currentHeat && this.currentHeat.challenges.find((el) => el.opponent.id === me && el.status === "Accepted")
      console.log('ACCEPTED', el);
      if (el) {
        return true
      }
      return false
    },
    currUserAwaiting() {
      const me = this.$store.state.auth.user.id;
      let el = this.competition.challenges.find((el) => el.opponent.id === me && el.status === "Waiting")
      if (el) {
        return true
      }
      return false
    },
    isShowWaitingResults() {
      return this.participation && this.isCurrentUserUploadedVideo
    },
    competitorsFiltered() {
      const comp = []
      for (let challange of this.competition.challenges) {
        if (this.competition.status === 'performances-are-marked-up'
          || this.competition.status === 'judging-is-done'
          || this.competition.status === 'video-post-prod-is-done'
          || this.competition.status === 'video-is-scheduled') {
          if (challange.status !== 'Waiting' && challange.status !== 'Rejected') {
            comp.push(challange.opponent)
          }
        } else {
          comp.push(challange.opponent)
        }
      }
      if (this.competition.challenges[0].initiator) {
        comp.push(this.competition.challenges[0].initiator)
      }
      return comp
    },
    currUserWaitingAfterMarks() {
      return this.currUserAwaiting && (
        this.competition.status === 'performances-are-marked-up'
        || this.competition.status === 'judging-is-done'
        || this.competition.status === 'video-post-prod-is-done'
        || this.competition.status === 'video-is-scheduled')
    },
    isOpenEvent() {
      return this.competition.type === 'open'
    },
    awaitingCreated() {
      if (this.competition.entity === 'event') {
        return this.currentHeat.status === 'awaiting' || this.currentHeat.status === 'created'
      } else {
        return this.competition.status === 'awaiting' || this.competition.status === 'created'
      }
    },
    regClosed() {
      if (this.competition.entity === 'event') {
        return this.currentHeat.status === 'registration-closed'
      }
      return this.competition.status === 'registration-closed'
    },
    startListRevealed() {
      if (this.competition.entity === 'event') {
        return this.currentHeat.status === 'start-list-revealed'
      }
      return this.competition.status === 'start-list-revealed'
    },
    afterStartList() {
      if (this.competition.entity === 'event') {
        return this.currentHeat && (this.currentHeat.status === 'performances-are-uploaded'
          || this.currentHeat.status === 'performances-are-marked-up'
          || this.currentHeat.status === 'judging-is-done'
          || this.currentHeat.status === 'video-post-prod-is-done'
          || this.currentHeat.status === 'video-is-scheduled')
      } else {
        return this.competition.status === 'performances-are-uploaded'
          || this.competition.status === 'performances-are-marked-up'
          || this.competition.status === 'judging-is-done'
          || this.competition.status === 'video-post-prod-is-done'
          || this.competition.status === 'video-is-scheduled'
      }

    },
    getCurrEventChallenge() {
      return this.currentHeat && this.currentHeat.challenges && this.currentHeat.challenges.find(el => el.opponent_id === this.$store?.state?.auth?.user?.id)
    },
    isParticipation() {
      if (!this.competition.me_has_challenges) {
        return this.getCurrEventChallenge
      }
      return this.competition.me_has_challenges
    },
    getCurrId() {
      if (this.competition.entity === 'event') {
        return this.currentHeat.id
      } else {
        return this.id
      }
    },
    meHasVideo() {
      return this.competition.me.has_video
    },
    isParticAndAccept() {
      return this.isParticipation && this.currUserAccepted
    },
    startListWithVideo() {
      return this.startListRevealed && this.isParticAndAccept && this.meHasVideo
    },
    startListWithoutVideo() {
      return this.startListRevealed && this.isParticAndAccept && !this.meHasVideo
    },
    isLive() {
      if (this.competition.entity === 'event') {
        if (!this.competition.competitions || this.competition.competitions.length === 0) {
          return false
        }
        if (this.competition.competitions.find(el => el.status !== 'video-is-live')) {
          return false
        }
        return true
      }
      return this.competition.status === 'video-is-live'
    },
    regOpen() {
      if (this.competition.entity === 'event') {
        console.log(this.currentHeat);
        return this.currentHeat && this.currentHeat.status === 'registration-open'
      }
      return this.competition.status === 'registration-open'
    }
  },
  methods: {
    async setPropCompetition() {
      this.propCometition = await getEvent(this.competition.id)
    },
    isCompetNow() {
      if (this.competition.in_progress) {
        return
      }

      if (this.competition.compete_now) {
        this.isShowPopupCompetNow = false
        this.$router.push({ name: 'details-event', params: { id: this.id } })
      } else {
        this.isShowPopupCompetNow = true
      }
    },
    eventCompeteNow() {
      console.log('ID', this.id);
      this.togglePopup()
    },
    togglePopup() {
      this.popup = !this.popup
    },
    competeAction() {
      // if (this.rejected) {
      //   return
      // }
      this.togglePopup()
    },
    share() {
      if (checkCanCompetitionShare(this.competition)) {
        this.isShowPopupShare = true
      } else {
        this.isShowPopupWait = true
      }
    },
    copySharedLink() {
      if (this.linkWasCopied) {
        return
      }
      let currUrl = this.competition.entity === 'competition' ? '/details/' : '/details-event/'
      copyTextToClipboard(
        `${window.location.origin}${currUrl}${this.competition.id}`
      )
      this.linkWasCopied = true
      setTimeout(() => {
        this.linkWasCopied = false
        this.isShowPopupShare = false
      }, 1500)
    },
  }
}
</script>

<style lang="css" scoped>
.competition__share {
  padding: 30px 0;
  color: #d8d8d8;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  position: relative;
}

.competition__share>button {
  display: flex;
  align-items: center;
  width: 100%;
}

.competition__share>button>span {
  margin-left: 17px;
}

.competition__copied {
  position: absolute;
  bottom: calc(100% - 25px);
}

.top-separator:before {
  content: "";
  border-top: 1px solid #2F354D;
  width: calc(100% + 32px);
  margin: 12px -16px;
  display: block;
}

.competition-component .details-wrap .details {
  padding: 0;
  background-color: transparent;
}

.competition-component .details-wrap .details .detail .name {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #7B8B97;
}

.competition-component .details-wrap .details .detail .value {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #EEEEEE;
}
</style>
