<template>
  <div class="competitors-component" ref="block">
    <Competitor :competitor="organizer" :competition="competition" />

    <div class='c__divider'><span>VS</span></div>

    <div class="d-flex align-items-center justify-content-between">
      <div class="list align-items-center ">
        <Competitor v-for="(competitor, index) in visibleAvatars" :key="`cm_${index}_${competitor.id}`"
          :competitor="competitor" :competition="competition" :isLink="false" mode="avatar" />

        <div v-if="competitors.length > avatarsPerRow" class='c__add-users'>+{{ competitors.length - avatarsPerRow + 1 }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Competitor from '@/components/Competitions/Competitor'

export default {
  name: 'Competitors',
  components: {
    Competitor
  },
  props: {
    competitors: {
      type: Array,
      required: true
    },
    organizer: {
      type: Object,
      required: true
    },
    competition: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      blockSize: 0
    }
  },
  computed: {
    listClasses() {
      return {
        full: this.competitors.length > 7
      }
    },
    getFirstCompetitors() {
      return this.competitors.length > 7 ? this.competitors.slice(0, 6) : this.competitors;
    },
    avatarsPerRow() {
      return Math.round(this.blockSize / 43);
    },
    visibleAvatars() {
      if (this.competitors.length <= this.avatarsPerRow) {
        return this.competitors;
      } else {
        return this.competitors.slice(0, this.avatarsPerRow - 1)
      }
    }
  },
  methods: {
    onResize() {
      this.blockSize = this.$refs.block.offsetWidth - 16;
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<style lang="stylus" scoped>
  .c {
    &__divider {
      display flex
      align-items: center;
      color: rgba(138, 144, 163, 0.6);
      margin 12px 0

      span {
        margin 0 10px
        flex-grow 0
        flex-shrink 0

        font-weight: 700;
        font-size: 10px;
        line-height: 1;
        letter-spacing: 0.15em;
        text-transform: uppercase;
      }

      &::after,
      &::before {
        content ""
        flex-grow 1
        flex-shrink 1
        height 0
        width 100%
        border-top: 1px solid #2F354D
      }
    }

    &__add-users {
      display flex
      justify-content center
      align-items center
      width 36px
      height 36px
      background-color: rgba(255, 255, 255, 0.2)
      border-radius 50%

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.7);
    }
  }

</style>
