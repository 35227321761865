<template>
  <div class="duel-c" :class="{opposite: isOpposite}">
    <router-link
      :to="{ name: 'account', params: { userID: id } }"
      class="avatar"
    >
      <CompetitorAvatar
        :avatarSize="avatarSize"
        :src="avatar"
        :name="name" class="duel-c__avatar"
      />
    </router-link>

    <div class="duel-c__name">
      <span> {{ name }} </span>
    </div>

    <div class="duel-c__info">
      <div class="duel-c__country" v-if="country">
        <img :src="country.icon" alt="Flag"/>
        <span> {{ country.code }} </span>
      </div>

      <div class="duel-c__rating">
        <Icon :size="16" type="star"/>

        <span> {{ raiting }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import CompetitorAvatar from '@/components/Competitions/Competitor/CompetitorAvatar'
import competitor from '@/mixins/competitor'

export default {
  components: { Icon, CompetitorAvatar },
  mixins: [ competitor ],
  props: {
    competitor: {
      type: Object,
      required: true
    },
    isOpposite: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      avatarSize: 40,
    }
  }
}
</script>

<style lang="stylus" scoped>
  .duel-c
    min-height 114px
    height: 100%;
    padding 8px 0
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    &__avatar
      margin-bottom: 4px

    &__name
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2
      color: #EEEEEE;
      margin-bottom: 4px

    &__info
      display flex
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #EEEEEE;

    &__country {
      display: flex;
      align-items: center;
      margin-right 12px

      img {
        width: 16px;
        height: 16px;
        object-fit: contain;
        margin-right 4px
      }
    }

    &__rating {
      display flex
      align-items center

      svg {
        margin 0 4px 0 0
      }
    }

    &.opposite {
      align-items: flex-end;

      .duel-c__name {
        text-align: right;
      }
    }
</style>
